var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.metaData,"mobile-breakpoint":_vm.mbreakpoint,"hide-default-footer":_vm.metaData.length <= 15,"footer-props":{
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50],
  },"expanded":_vm.expanded,"show-expand":"","single-expand":"","sort-by":['kind']},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.kind",fn:function({ item }){return [_vm._v(" "+_vm._s(item.kind)+" ")]}},{key:"item.metadata",fn:function({ item }){return _vm._l((item.metadata),function(meta,index){return _c('v-chip',{key:index,staticClass:"mt-1 mb-1 mr-1",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(meta)+" ")])})}},{key:"item.data-table-expand",fn:function({ item, isExpanded, expand }){return [(
        _vm.isValidKey(item.kind) &&
        !_vm.isReadOnlyKey(item.kind) &&
        _vm.$store.getters.isStaffAdmin
      )?_c('v-btn',{attrs:{"color":"staff","icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("coming soon ...")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }