<template>
  <v-data-table
    :headers="headers"
    :items="metaData"
    :mobile-breakpoint="mbreakpoint"
    :hide-default-footer="metaData.length <= 15"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageOptions: [15, 50],
    }"
    :expanded.sync="expanded"
    show-expand
    single-expand
    :sort-by="['kind']"
  >
    <template v-slot:item.kind="{ item }">
      {{ item.kind }}
    </template>

    <template v-slot:item.metadata="{ item }">
      <v-chip
        v-for="(meta, index) in item.metadata"
        :key="index"
        class="mt-1 mb-1 mr-1"
        outlined
      >
        {{ meta }}
      </v-chip>
    </template>

    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-btn
        v-if="
          isValidKey(item.kind) &&
          !isReadOnlyKey(item.kind) &&
          $store.getters.isStaffAdmin
        "
        color="staff"
        @click="expand(!isExpanded)"
        icon
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">coming soon ...</td>
    </template>
  </v-data-table>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneMetaData",
  mixins: [isMobile],
  props: {
    value: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data: () => ({
    expanded: [],
    search: "",
    metaData: [],
    knownValues: [
      { kind: "ALLOW-AXFR-FROM" },
      { kind: "API-RECTIFY", readOnly: true },
      { kind: "AXFR-SOURCE" },
      { kind: "ALLOW-DNSUPDATE-FROM" },
      { kind: "TSIG-ALLOW-DNSUPDATE" },
      { kind: "FORWARD-DNSUPDATE" },
      { kind: "SOA-EDIT-DNSUPDATE" },
      { kind: "NOTIFY-DNSUPDATE" },
      { kind: "ALSO-NOTIFY" },
      { kind: "AXFR-MASTER-TSIG", readOnly: true },
      { kind: "GSS-ALLOW-AXFR-PRINCIPAL" },
      { kind: "GSS-ACCEPTOR-PRINCIPAL" },
      { kind: "IXFR" },
      { kind: "LUA-AXFR-SCRIPT", readOnly: true },
      { kind: "NSEC3NARROW", readOnly: true },
      { kind: "NSEC3PARAM", readOnly: true },
      { kind: "PRESIGNED", readOnly: true },
      { kind: "PUBLISH-CDNSKEY" },
      { kind: "PUBLISH-CDS" },
      { kind: "SLAVE-RENOTIFY" },
      { kind: "SOA-EDIT" },
      { kind: "SOA-EDIT-API", readOnly: true },
      { kind: "TSIG-ALLOW-AXFR", readOnly: true },
      { kind: "TSIG-ALLOW-DNSUPDATE" },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("kind"),
          value: "kind",
        },
        {
          text: this.$i18n.t("meta data"),
          value: "metadata",
          sortable: false,
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        var that = this;
        let i = 0;
        this.metaData = [];
        this.value.forEach(function (v) {
          i = i + 1;
          v.id = i;
          that.metaData.push(v);
        });
      },
    },
  },
  methods: {
    isValidKey(key) {
      return (
        key &&
        (this.knownValues.filter(function (value) {
          return value.kind == key.toUpperCase();
        }).length > 0 ||
          key.startsWith("X-"))
      );
    },
    isReadOnlyKey(key) {
      return (
        key &&
        this.knownValues.filter(function (value) {
          return value.kind == key.toUpperCase() && value.readOnly;
        }).length > 0
      );
    },

    update() {},
  },
};
</script>

<i18n>
{
  "en": {
    "search": "Search",
    "kind": "Kind",
    "meta data": "Meta data"
  },
  "de": {
    "search": "Suche",
    "kind": "Typ",
    "meta data": "Meta Data"
  }
}
</i18n>
