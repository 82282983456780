<template>
  <v-row align="end" dense>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Service'"
        :name="$t('service')"
        rules="required|min:1|max:24"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="service"
          :name="name + 'Service'"
          :label="$t('service')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Proto'"
        :name="$t('protocol')"
        rules="required|alpha"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="protocol"
          :name="name + 'Proto'"
          :label="$t('protocol')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Prio'"
        :name="$t('priority')"
        rules="required|integer|min:0|max:65535"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="priority"
          :name="name + 'Prio'"
          :label="$t('priority')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          type="number"
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Weight'"
        :name="$t('weight')"
        rules="required|integer|min:0|max:65535"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="weight"
          :name="name + 'Weight'"
          :label="$t('weight')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          type="number"
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Port'"
        :name="$t('port')"
        rules="required|integer|min:0|max:65535"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="port"
          :name="name + 'Port'"
          :label="$t('port')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          type="number"
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Target'"
        :name="$t('target')"
        rules="required|dns"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="target"
          :name="name + 'Target'"
          :label="$t('target')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordSrv",
  mixins: [formatDnsEntry, isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hostname: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data: () => ({
    host: null,
    service: null,
    protocol: null,
    priority: null,
    weight: null,
    port: null,
    target: null,
    disabled: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        var that = this;
        if (this.value != null) {
          var arr = (this.value.content || "").split(" ");
          if (arr.length > 0) this.priority = Number(arr[0] || 0) || null;
          if (arr.length > 1) this.weight = Number(arr[1] || 0) || null;
          if (arr.length > 2) this.port = Number(arr[2] || 0) || null;
          if (arr.length > 3) this.target = arr.slice(3).join(".");
          this.disabled = this.value.disabled;
        }
        if (this.hostname != null) {
          var arrSP = (this.hostname || "").split(".").filter(function (h) {
            return h && h.charAt(0) == "_";
          });
          var arrH = (this.hostname || "")
            .split(".")
            .filter(function (h) {
              return h != "" && h.trim() != "";
            })
            .slice(arrSP.length);
          if (arrSP.length > 0) this.service = this.trim(arrSP[0]);
          if (arrSP.length > 1) this.protocol = this.trim(arrSP[1]);
          this.host = arrH.join(".");
        }
      },
    },
  },
  methods: {
    trim(s) {
      var regexRemoveEndWith = new RegExp("\_+$");
      var regexRemoveStartWith = new RegExp("^\_+");
      return (s || "")
        .replace(regexRemoveEndWith, "")
        .replace(regexRemoveStartWith, "");
    },
    update() {
      this.$emit("input", {
        content:
          (this.priority != null ? this.priority : 0) +
          " " +
          (this.weight != null ? this.weight : 0) +
          " " +
          (this.port != null ? this.port : 0) +
          " " +
          (this.target != null ? this.target : ""),
        disabled: this.disabled,
      });
      this.$emit(
        "input-hostname",
        (this.service ? "_" + this.trim(this.service) : "_") +
          "." +
          (this.protocol ? "_" + this.trim(this.protocol) : "_") +
          "." +
          this.host
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "service": "Service",
    "protocol": "Protocol",
    "priority": "Priority",
    "weight": "Weight",
    "port": "Port",
    "target": "Target"
  },
  "de": {
    "service": "Service",
    "protocol": "Protokoll",
    "priority": "Priorität",
    "weight": "Gewicht",
    "port": "Port",
    "target": "Ziel"
  }
}
</i18n>
