<template>
  <v-card flat class="my-2">
    <validation-observer
      :vid="name + 'Obs'"
      v-slot="{ errors, invalid, validated, dirty, pristine }"
      slim
    >
      <v-card-text>
        <v-container>
          <v-row align="end" dense>
            <v-col cols="4">
              <validation-provider
                :vid="name + 'Hostname'"
                :name="$t('hostname')"
                rules="required|max:255"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-text-field
                  v-model="rrset.name"
                  :name="name + 'Hostname'"
                  :label="$t('hostname')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  single-line
                  :clearable="!isMobile"
                  :disabled="readOnly"
                  dense
                  v-disabled-icon-focus
                />
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <ttl-combobox
                v-model="rrset.ttl"
                :name="name + 'Ttl'"
                :label="$t('TTL')"
                :read-only="readOnly"
              />
            </v-col>
            <v-col cols="4">
              <rrset-type-combobox
                v-model="rrset.type"
                :name="name + 'Type'"
                :label="$t('type')"
                :read-only="readOnly"
              />
            </v-col>
          </v-row>

          <v-row
            v-for="(rec, index) in rrset.records"
            :key="index"
            align="center"
            dense
          >
            <v-col v-if="rrset.type == 'A' || rrset.type == 'AAAA'" cols="10">
              <zone-record-a
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                :ipv6="rrset.type == 'AAAA'"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'MX'" cols="10">
              <zone-record-mx
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'SOA'" cols="10">
              <zone-record-soa
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'TXT'" cols="10">
              <zone-record-txt
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'SRV'" cols="10">
              <zone-record-srv
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                :hostname="rrset.name"
                @input="rrset.records[index] = $event"
                @input-hostname="rrset.name = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'HTTPS'" cols="10">
              <zone-record-https
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                :hostname="rrset.name"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col v-else-if="rrset.type == 'LOC'" cols="10">
              <zone-record-loc
                :value="rec"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                :hostname="rrset.name"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col cols="10" v-else>
              <zone-record-default
                :value="rec"
                :type="rrset.type"
                :name="'record' + rrset.type + index"
                :read-only="readOnly"
                @input="rrset.records[index] = $event"
              />
            </v-col>
            <v-col cols="2" v-if="rrset.type == 'SOA'"> </v-col>
            <v-col cols="2" v-else>
              <v-btn
                icon
                color="primary"
                @click="recordUp(rec, index)"
                :disabled="readOnly"
                class="mb-3"
                small
              >
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="recordDown(rec, index)"
                :disabled="readOnly"
                class="mb-3"
                small
              >
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                @click="recordDelete(rec, index)"
                :disabled="readOnly"
                class="mb-3"
                small
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="
                  rrset.records.splice(index + 1, 0, {
                    content: '',
                    disabled: false,
                  })
                "
                :disabled="readOnly"
                class="mb-3"
                small
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="$emit('delete')"
          :disabled="
            readOnly || rrset.changetype == 'DELETE' || rrset.type == 'SOA'
          "
          text
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t("delete resource record") }}
        </v-btn>
        <v-spacer />
        <v-btn :disabled="readOnly || invalid" @click="takeOver" text>
          <v-icon left> mdi-check-circle </v-icon>
          {{ $t("apply") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import TtlCombobox from "@/components/services/domains/TtlCombobox";
import RrsetTypeCombobox from "@/components/services/domains/RrsetTypeCombobox";
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";
import ZoneRecordDefault from "./ZoneRecordDefault";
import ZoneRecordA from "./ZoneRecordA";
import ZoneRecordMx from "./ZoneRecordMx";
import ZoneRecordSoa from "./ZoneRecordSoa";
import ZoneRecordTxt from "./ZoneRecordTxt";
import ZoneRecordSrv from "./ZoneRecordSrv";
import ZoneRecordHttps from "./ZoneRecordHttps";
import ZoneRecordLoc from "./ZoneRecordLoc";

export default {
  name: "ZoneRrsetEdit",
  mixins: [formatDnsEntry, isMobile],
  components: {
    TtlCombobox,
    RrsetTypeCombobox,
    ZoneRecordDefault,
    ZoneRecordA,
    ZoneRecordMx,
    ZoneRecordSoa,
    ZoneRecordTxt,
    ZoneRecordSrv,
    ZoneRecordHttps,
    ZoneRecordLoc,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    usedRrsets: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    rrset: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        this.rrset = { ...this.value };
        this.rrset.name = this.formatDnsEntry(this.rrset.name, "NS");
        this.rrset.records = this.value.records.map(function (rec) {
          return { ...rec };
        });
      },
    },
  },
  methods: {
    recordUp(rec, index) {
      if (index > 0 && this.rrset != null && this.rrset.records.length > 1) {
        this.rrset.records.splice(index, 1);
        this.rrset.records.splice(index - 1, 0, rec);
      }
    },
    recordDown(rec, index) {
      if (
        this.rrset != null &&
        this.rrset.records.length > 1 &&
        index + 1 < this.rrset.records.length
      ) {
        this.rrset.records.splice(index, 1);
        this.rrset.records.splice(index + 1, 0, rec);
      }
    },
    recordDelete(rec, index) {
      this.rrset.records.splice(index, 1);
    },
    takeOver() {
      var that = this;
      var rrset = { ...this.rrset };
      rrset.name = that.formatDnsEntryReverse(rrset.name, "NS");
      rrset.records = this.rrset.records.map(function (rec) {
        return {
          content: that.formatDnsEntryReverse(rec.content, that.rrset.type),
          disabled: rec.disabled,
        };
      });
      this.$emit("input", rrset);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "hostname": "Hostname",
    "TTL": "TTL",
    "type": "Type",
    "apply": "apply",
    "add record": "add record",
    "delete resource record": "delete resource record"
  },
  "de": {
    "hostname": "Hostname",
    "TTL": "TTL",
    "type": "Typ",
    "apply": "übernehmen",
    "add record": "Eintrag hinzufügen",
    "delete resource record": "Resource Eintrag löschen"
  }
}
</i18n>
