<template>
  <validation-provider
    :vid="name"
    :name="$t('content')"
    :rules="'required' + (dnsRule ? '|dns' : '')"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-text-field
      v-model="record.content"
      :name="name"
      :label="$t('content')"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      single-line
      :clearable="!isMobile"
      :disabled="readOnly"
      dense
      v-disabled-icon-focus
      @input="update"
    />
  </validation-provider>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordDefault",
  mixins: [formatDnsEntry, isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    type: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop == null,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    record: null,
  }),
  computed: {
    dnsRule() {
      this.type;
      return ["NS", "PTR", "CNAME"].includes((this.type || "").toUpperCase());
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          this.record = { ...this.value };
        }
      },
    },
  },
  methods: {
    update() {
      var rec = { ...this.record };
      rec.content = rec.content;
      this.$emit("input", rec);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "content": "Content"
  },
  "de": {
    "content": "Inhalt"
  }
}
</i18n>
