<template>
  <validation-provider
    :vid="name"
    :name="label"
    rules="required|numeric|min_value:300"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-combobox
      v-model="ttl"
      :name="name"
      :items="choices"
      :label="label"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      type="number"
      :clearable="!isMobile"
      :disabled="readOnly"
      open-on-clear
      dense
      v-disabled-icon-focus
      @input="$emit('input', ttl)"
    >
      <template v-slot:selection="{ item }">
        <span class="item-value">{{ item }}</span>
        <span class="item-text">
          {{ choices.includes(item) ? " (" + $t("ttl" + item) + ")" : "" }}
        </span>
      </template>

      <template v-slot:item="{ item }">
        <span class="item-value">{{ item }}</span>
        <span class="item-text">
          {{ choices.includes(item) ? "(" + $t("ttl" + item) + ")" : "" }}
        </span>
      </template>

      <template v-slot:no-data>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("Press") }} <kbd>enter</kbd>
              {{ $t("to create a new value") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "TtlCombobox",
  mixins: [isMobile],
  props: {
    value: {
      required: true,
      validator: (prop) =>
        typeof prop === "number" || typeof prop === "string" || prop == null,
    },
    choices: {
      type: Array,
      required: false,
      default: () => [
        300, 600, 1800, 3600, 7200, 14400, 28800, 43200, 86400, 172800, 1209600,
      ],
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ttl: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.ttl = value != null ? Number(value) : null;
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "ttl300": "5 minutes",
    "ttl600": "10 minutes",
    "ttl1800": "30 minutes",
    "ttl3600": "1 hour",
    "ttl7200": "2 hours",
    "ttl14400": "4 hours",
    "ttl28800": "8 hours",
    "ttl43200": "12 hours",
    "ttl86400": "1 day",
    "ttl172800": "2 days",
    "ttl604800": "1 week",
    "ttl1209600": "2 weeks",
    "Press": "Press",
    "to create a new value": "to create a new value"
  },
  "de": {
    "ttl300": "5 Minuten",
    "ttl600": "10 Minuten",
    "ttl1800": "30 Minuten",
    "ttl3600": "1 Stunde",
    "ttl7200": "2 Stunden",
    "ttl14400": "4 Stunden",
    "ttl28800": "8 Stunden",
    "ttl43200": "12 Stunden",
    "ttl86400": "1 Tag",
    "ttl172800": "2 Tage",
    "ttl604800": "1 Woche",
    "ttl1209600": "2 Wochen",
    "Press": "Drücke",
    "to create a new value": "um einen neuen Wert anzulegen"
  }
}
</i18n>

<style scoped>
.item-value {
  margin-left: 6px;
}
.item-text {
  margin-left: 6px;
  margin-right: 8px;
  font-style: italic;
}
</style>
