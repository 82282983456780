<template>
  <validation-provider
    :vid="name"
    :name="$t('Loc')"
    rules="required|loc_record"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-text-field
      v-model="record.content"
      :name="name"
      :label="$t('Loc')"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      single-line
      :clearable="!isMobile"
      :disabled="readOnly"
      dense
      v-disabled-icon-focus
      @input="update"
    />
    
    <div style="height: 350px;">
    <l-map
      style="height: 100%; width: 100%"
      :zoom="zoom"
      :center="center"
      :options="{worldCopyJump:true}"
      :bounds="bounds"
      :max-bounds="maxBounds"
      :minZoom="minZoom"
      @click="updateMarker"
    >
      <l-tile-layer :url="url" :attribution="attribution" continuousWorld="false" :noWrap="true"></l-tile-layer>
      <l-marker :lat-lng="markerLatLng"></l-marker>
    </l-map>
  </div>
  </validation-provider>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import { latLngBounds, latLng } from "leaflet";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  name: "ZoneRecordLoc",
  mixins: [isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    ipv6: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      minZoom: 3,
      center: null,
      markerLatLng: null,
      maxBounds: latLngBounds([
        [90, -180],
        [-90, 180]
      ]),
      bounds: latLngBounds([
        [90, -180],
        [-90, 180]
      ]),
    record: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          this.record = { ...this.value };
        }
      },
    },
  },
  methods: {
    update() {
      var rec = { ...this.record };
      this.$emit("input", rec);
    },
    updateMarker(e)
    {
      this.markerLatLng = e.latlng;
  
      let lat = this.markerLatLng.lat;
      let lng = this.markerLatLng.lng;
      let dirLat = this.markerLatLng.lat < 0 ? "S" : "N";
      let dirLng = this.markerLatLng.lng < 0 ? "W" : "E";

      // Converts decimal degrees to degrees minutes seconds. https://gist.github.com/mbykovskyy/1c67b0b4ba8da9972488
      let absDdLat = Math.abs(lat);
      let degLat = absDdLat | 0;
      let fracLat = absDdLat - degLat;
      let minLat = (fracLat * 60) | 0;
      let secLat = fracLat * 3600 - minLat * 60;
      secLat = (secLat).toFixed(3);

      let absDdLng = Math.abs(lng);
      let degLng = absDdLng | 0;
      let fracLng = absDdLng - degLng;
      let minLng = (fracLng * 60) | 0;
      let secLng = fracLng * 3600 - minLng * 60;
      secLng = (secLng).toFixed(3);

      let standardValues = " 10.00m" + " 1.00m" + " 10000.00m" + " 10.00m";

      this.record.content = degLat + " " + minLat + " " + secLat + " " + dirLat + " " + degLng + " " + minLng + " " + secLng + ' ' + dirLng + standardValues;
      this.update();   
    },
  },
  mounted() {
    this.center = latLng(47, 8);
    this.markerLatLng = latLng(47, 8);
  },
};
</script>

<i18n>
{
  "en": {
    "Loc": "Location"
  },
  "de": {
    "Loc": "Standort"
  }
}
</i18n>
