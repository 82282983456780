<template>
  <validation-provider
    :vid="name"
    :name="label"
    :rules="(required ? 'required|' : '') + 'alpha|min:1|max:5'"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-combobox
      v-model="type"
      :name="name"
      :items="choices"
      :label="label"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      :clearable="!isMobile"
      :disabled="readOnly"
      :filter="filterChoices"
      dense
      open-on-clear
      auto-select-first
      v-disabled-icon-focus
      @input="$emit('input', type)"
    >
      <template v-slot:selection="{ item }">
        <span class="item-value">{{ item }}</span>
        <span class="item-text">
          {{ choices.includes(item) ? " (" + $t("type" + item) + ")" : "" }}
        </span>
      </template>

      <template v-slot:item="{ item }">
        <span class="item-value">{{ item }}</span>
        <span class="item-text">
          {{ choices.includes(item) ? "(" + $t("type" + item) + ")" : "" }}
        </span>
      </template>

      <template v-slot:no-data>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("Press") }} <kbd>enter</kbd>
              {{ $t("to create a new value") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RrsetTypeCombobox",
  mixins: [isMobile],
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop == null,
    },
    choices: {
      type: Array,
      required: false,
      default: () => [
        "A",
        "AAAA",
        "MX",
        "NS",
        "PTR",
        "CNAME",
        "TXT",
        "HINFO",
        "RP",
        "SRV",
        "LOC",
        "DS",
        "CAA",
        "TLSA",
        "HTTPS",
      ],
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    type: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.type = String(value || "");
      },
    },
  },
  methods: {
    filterChoices(item, queryText, itemText) {
      return false;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "typeA": "IPv4 Address",
    "typeAAAA": "IPv6 Address",
    "typeMX": "Mail Exchange",
    "typeNS": "Name Server",
    "typePTR": "Pointer",
    "typeCNAME": "Canonical Name",
    "typeTXT": "Text",
    "typeHINFO": "Host Information",
    "typeRP": "Responsible Person",
    "typeSRV": "Service Locator",
    "typeLOC": "Location",
    "typeDS": "Delegation Signer",
    "typeCAA": "Certification Authority Authorization",
    "typeTLSA": "TLSA Certificate Association",
    "typeHTTPS": "HTTPS Service",
    "Press": "Press",
    "to create a new value": "to create a new value"
  },
  "de": {
    "typeA": "IPv4 Address",
    "typeAAAA": "IPv6 Address",
    "typeMX": "Mail Exchange",
    "typeNS": "Name Server",
    "typePTR": "Pointer",
    "typeCNAME": "Canonical Name",
    "typeTXT": "Text",
    "typeHINFO": "Host Information",
    "typeRP": "Responsible Person",
    "typeSRV": "Service Locator",
    "typeLOC": "Location",
    "typeDS": "Delegation Signer",
    "typeCAA": "Certification Authority Authorization",
    "typeTLSA": "TLSA Certificate Association",
    "typeHTTPS": "HTTPS Dienst",
    "Press": "Drücke",
    "to create a new value": "um einen neuen Wert anzulegen"
  }
}
</i18n>

<style scoped>
.item-value {
  margin-left: 6px;
}
.item-text {
  margin-left: 6px;
  margin-right: 8px;
  font-style: italic;
}
</style>
