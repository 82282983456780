<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title v-if="zone != null">
        {{ zone.name }}
        <span v-if="isIdn"> (IDN {{ zoneIdentifier }}) </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="loading || deleting || exporting || zone == null"
            :loading="exporting"
            @click="exportZone"
            icon
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("export zone") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loading"
            :disabled="loading || zone == null"
            @click="getZone"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload zone") }}</span>
      </v-tooltip>
      <wiki slug="dns-zone-edit" />
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />

    <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
      <v-card-text>
        <v-expansion-panels v-model="panelIndex">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("zone resource records") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="zone != null">
              <v-data-table
                :headers="headers"
                :items="filteredRrsets"
                :loading="loading"
                :mobile-breakpoint="mbreakpoint"
                :search="search"
                :custom-filter="customFilter"
                :hide-default-footer="rrsets.length <= 15"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [15, 50],
                }"
                :expanded.sync="expanded"
                show-expand
                single-expand
                show-group-by
                multi-sort
                :sort-by="['name']"
              >
                <template v-slot:top>
                  <v-row align="end" dense>
                    <v-col cols="8">
                      <v-text-field
                        class="mb-4"
                        v-model="search"
                        append-outer-icon="mdi-magnify"
                        clear-icon="mdi-close-circle"
                        :clearable="!isMobile"
                        v-bind:label="$t('search')"
                        single-line
                        hide-details
                      />
                    </v-col>
                    <v-col cols="4">
                      <rrset-type-combobox
                        v-model="searchType"
                        name="searchType"
                        :label="$t('type')"
                        :required="false"
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.name="{ item }">
                  {{ formatDnsEntry(item.name, "NS") }}
                </template>

                <template v-slot:item.records="{ item }">
                  <v-chip
                    v-for="(rec, index) in item.records"
                    :key="index"
                    :disabled="rec.disabled"
                    class="mt-1 mb-1 mr-1"
                    outlined
                  >
                    <span
                      class="d-inline-block text-truncate"
                      :style="'max-width:' + dnsEntrySize + 'px;'"
                    >
                      {{ formatDnsEntry(rec.content, item.type) }}
                    </span>
                  </v-chip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <zone-rrset-edit
                      :value="item"
                      :name="'rrset' + item.id"
                      :read-only="isReadOnly"
                      @input="updateRrset(item, $event)"
                      @delete="deleteRrset(item)"
                    />
                  </td>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-icon v-if="item.changetype == 'REPLACE'" color="success">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else-if="item.changetype == 'DELETE'" color="error">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$store.getters.isStaff">
            <v-expansion-panel-header class="staff--text">
              {{ $t("zone settings") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="zone != null">
              <v-row dense>
                <v-col cols="4">{{ $t("kind") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneKind"
                    :name="$t('kind')"
                    rules="required"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-select
                      v-model="zone.kind"
                      name="zoneKind"
                      :label="$t('kind')"
                      :items="zoneKinds"
                      :disabled="isReadOnly"
                      item-text="text"
                      item-value="value"
                      dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                dense
                v-if="
                  zone != null && zone.kind == 'Slave' && transferPhoneNumber
                "
              >
                <v-col cols="4">{{ $t("masters") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneMasters"
                    :name="$t('masters')"
                    rules="required_if:zoneKind,Slave|ip_list"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-combobox
                      v-model="zone.masters"
                      name="zoneMasters"
                      :label="$t('masters')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :items="[]"
                      multiple
                      dense
                      deletable-chips
                      :clearable="!isMobile"
                      :disabled="isReadOnly"
                      chips
                      @input="normalizeMasters"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          outlined
                          close
                          @click:close="removeMaster(item)"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">{{ $t("SOA edit") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneSoaEdit"
                    :name="$t('SOA edit')"
                    rules="numeric"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="zone.soa_edit"
                      name="zoneSoaEdit"
                      :label="$t('SOA edit')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="isReadOnly"
                      dense
                      type="number"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">{{ $t("SOA edit API") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneSoaEditApi"
                    :name="$t('SOA edit API')"
                    rules="numeric"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="zone.soa_edit_api"
                      name="zoneSoaEditApi"
                      :label="$t('SOA edit API')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="isReadOnly"
                      dense
                      type="number"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">{{ $t("DNSSEC") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneDnssec"
                    :name="$t('DNSSEC')"
                    rules=""
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-switch
                      v-model="zone.dnssec"
                      name="zoneDnssec"
                      :label="$t('DNSSEC')"
                      :disabled="isReadOnly"
                      dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">{{ $t("NSEC3 param") }}</v-col>
                <v-col cols="8">
                  <validation-provider
                    vid="zoneNsec3param"
                    :name="$t('NSEC3 param')"
                    rules="required_if:zoneDnssec,true|max:255"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="zone.nsec3param"
                      name="zoneNsec3param"
                      :label="$t('NSEC3 param')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="isReadOnly"
                      dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$store.getters.isStaff">
            <v-expansion-panel-header class="staff--text">
              {{ $t("zone meta data") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="zone != null">
              <zone-meta-data v-model="zone.meta_data" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <confirm-btn
          :disabled="loading || deleting || isReadOnly"
          :loading="deleting"
          :title="$t('delete zone?')"
          :message="$t('deleteZoneMsg')"
          :confirm-btn-text="$t('delete')"
          :cancel-btn-text="$t('cancel')"
          @confirm="deleteZone"
          text
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t("delete zone") }}
        </confirm-btn>
        <v-spacer />
        <v-btn
          :disabled="loading || zone == null || isReadOnly"
          @click="addRrset"
          text
        >
          <v-icon left> mdi-plus-circle </v-icon>
          {{ $t("add resource record") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="loading || invalid || isReadOnly"
          :loading="saving"
          @click="saveZone"
        >
          <v-icon left> mdi-content-save-move </v-icon>
          {{ $t("save zone") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import showErrors from "@/utils/mixins/showErrors";
import TtlCombobox from "../../components/services/domains/TtlCombobox";
import RrsetTypeCombobox from "../../components/services/domains/RrsetTypeCombobox";
import ZoneRrsetEdit from "../../components/services/domains/ZoneRrsetEdit";
import ZoneMetaData from "../../components/services/domains/ZoneMetaData";
import ConfirmBtn from "../../components/basics/ConfirmBtn";
import downloadFile from "@/utils/mixins/downloadFile";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

const moment = require("moment");
const ip = require("@/utils/ip");

export default {
  name: "DomainZoneDetails",
  mixins: [showErrors, formatDateTime, formatDnsEntry, downloadFile, isMobile],
  components: {
    ConfirmBtn,
    TtlCombobox,
    RrsetTypeCombobox,
    ZoneRrsetEdit,
    ZoneMetaData,
    Wiki,
  },
  props: {
    zoneId: {
      type: String,
      require: true,
    },
    nameServers: {
      type: Array,
      require: false,
      default: () => [],
    },
    zoneFile: {
      type: String,
      require: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      require: false,
      default: false,
    },
    defaultHostmasterEmail: {
      type: String,
      require: false,
      default: "hostmaster@as8758.net",
    },
    primaryNS: {
      type: String,
      require: false,
      default: "ns1.as8758.net",
    },
    defaultRefresh: {
      type: Number,
      require: false,
      default: 86400,
    },
    defaultRetry: {
      type: Number,
      require: false,
      default: 1209600,
    },
    defaultExpire: {
      type: Number,
      require: false,
      default: 3600,
    },
    domainId: {
      type: [String, Number],
      require: false,
      default: null,
    },
  },
  data: () => ({
    panelIndex: 0,
    expanded: [],
    loading: false,
    saving: false,
    deleting: false,
    exporting: false,
    zone: null,
    loadingDelete: false,
    search: null,
    searchType: null,
    soa: null,
    rrsets: [],
  }),
  watch: {
    zoneId: {
      immediate: true,
      handler(value) {
        if (value != null) this.getZone();
        else this.zone = null;
      },
    },
  },
  computed: {
    zoneIdentifier() {
      if (this.zone != null)
        return this.zone.id.substr(0, this.zone.id.length - 1);
      return null;
    },
    isIdn() {
      return this.zoneIdentifier && this.zone.name != this.zoneIdentifier;
    },
    headers() {
      return [
        {
          text: this.$i18n.t("hostname"),
          value: "name",
          groupable: true,
          sort: (a, b) => {
            let aa = a.split(".");
            let bb = b.split(".");
            if (aa.length == bb.length) return a.localeCompare(b);
            return aa.length < bb.length ? -1 : 1;
          },
        },
        {
          text: this.$i18n.t("ttl"),
          value: "ttl",
          groupable: false,
        },
        {
          text: this.$i18n.t("type"),
          value: "type",
          groupable: true,
        },
        {
          text: this.$i18n.t("records"),
          value: "records",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "action", sortable: false, groupable: false },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          groupable: false,
        },
      ];
    },
    zoneKinds() {
      return [
        { text: this.$i18n.t("zoneKindNative"), value: "Native" },
        { text: this.$i18n.t("zoneKindMaster"), value: "Master" },
        { text: this.$i18n.t("zoneKindSlave"), value: "Slave" },
      ];
    },
    filteredRrsets() {
      this.rrsets;
      this.searchType;
      var that = this;
      return this.rrsets.filter(function (rrset) {
        if (that.searchType != null && that.searchType.trim() != "")
          return rrset.type == that.searchType.toUpperCase();
        return true;
      });
    },
    syncedRrsets() {
      this.rrsets;
      return this.rrsets.filter(function (rrset) {
        return rrset.changetype != null && rrset.changetype.trim() != "";
      });
    },
    lastId() {
      this.rrsets;
      var id = 0;
      this.rrsets.forEach(function (rrset) {
        if (rrset.id > id) id = rrset.id;
      });
      return id;
    },
    defaultSoaContent() {
      return (
        this.formatDnsEntryReverse(this.primaryNS, "NS") +
        " " +
        this.formatDnsEntryReverse(this.defaultHostmasterEmail, "NS") +
        " " +
        this.Number(moment().format("YYYYMMDD") + "01") +
        " " +
        this.defaultRefresh +
        " " +
        this.defaultRetry +
        " " +
        this.defaultExpire +
        " " +
        this.defaultRefresh
      );
    },
    dnsEntrySize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    isReadOnly() {
      return this.readOnly || this.zone == null || this.zone.read_only || false;
    },
  },
  methods: {
    mergeRrsets(rrsets, mergedRrsets = {}) {
      var that = this;
      rrsets.forEach(function (rrset) {
        let key = rrset.type + ":" + that.trimDots(rrset.name || "");
        if (mergedRrsets[key] == null) mergedRrsets[key] = { ...rrset };
        else {
          let current = mergedRrsets[key];
          let ttl = rrset.ttl || 0;
          let comments = rrset.comments || [];
          let changetype = rrset.changetype || null;
          let records = rrset.records || [];
          if (current.ttl < ttl) current.ttl = ttl;
          current.comments = [...current.comments, ...comments];
          current.changetype =
            current.changetype == null || current.changetype.trim() == ""
              ? changetype
              : current.changetype == "DELETE"
              ? changetype
              : current.changetype;
          current.records = [...current.records, ...records];
        }
      });
      return { ...mergedRrsets };
    },
    mergeAllRrsets(rrsets) {
      var syncedRrsets = rrsets.filter(function (rrset) {
        return rrset.changetype != null && rrset.changetype.trim() != "";
      });
      var unSyncedRrsets = rrsets.filter(function (rrset) {
        return rrset.changetype == null || rrset.changetype.trim() == "";
      });
      var mergedRrsets = this.mergeRrsets(unSyncedRrsets, {});
      mergedRrsets = this.mergeRrsets(syncedRrsets, mergedRrsets);
      return Object.values(mergedRrsets);
    },
    customFilter(value, search, item) {
      return (
        (item.name != null &&
          item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
        (item.type != null &&
          item.type.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
        (item.records != null &&
          item.records.filter(function (rec) {
            return (
              rec.content != null &&
              rec.content.toLowerCase().indexOf(search.toLowerCase()) >= 0
            );
          }).length > 0)
      );
    },
    removeMaster(value) {
      this.zone.masters = this.zone.masters.filter(function (e) {
        return e != value;
      });
    },
    normalizeMasters() {
      if (this.zone != null && this.zone.masters != null)
        this.zone.masters = this.zone.masters
          .map(function (e) {
            return ip.normalize(e);
          })
          .filter(function (e) {
            return e != null;
          });
      else this.zone.masters = [];
    },
    getZone() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/dns/forward/" + this.zoneId)
        .then((response) => {
          var i = 1;
          that.zone = response.data;
          that.rrsets = this.zone.rrsets.map(function (rec) {
            i = i + 1;
            return {
              ...rec,
              id: i, // add required table key
              changetype: null,
            };
          });
          var soas = that.rrsets.filter(function (rec) {
            return rec.type == "SOA";
          });
          if (soas.length == 0) {
            var soa = {
              name: "",
              type: "Native",
              ttl: that.defaultExpire,
              records: [
                {
                  content: that.defaultSoaContent,
                  disabled: false,
                },
              ],
              comments: [],
            };
            that.rrsets.unshift(soa);
            that.soa = soa; // use pointer !!!
          } else that.soa = soas[0]; // use pointer !!!
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    addRrset() {
      var rrset = {
        id: this.lastId + 1,
        name: this.zone.name,
        ttl: this.soa.ttl,
        type: "",
        comments: [],
        records: [{ content: "", disabled: false }],
        changetype: null,
        isNew: true,
      };
      this.rrsets = [rrset, ...this.rrsets];
      this.expanded = [rrset];
      this.searchType = null;
      this.search = null;
    },
    deleteRrset(rrset) {
      this.expanded = [];
      if (rrset.isNew)
        this.rrsets = this.rrsets.filter(function (r) {
          return r.id != rrset.id;
        });
      else rrset.changetype = "DELETE";
    },
    updateRrset(updatedRrset, newRrset) {
      this.expanded = [];
      newRrset.changetype = "REPLACE";
      var rrsets = this.rrsets.map((rrset) =>
        rrset.id == updatedRrset.id ? newRrset : rrset
      );
      this.rrsets = this.mergeAllRrsets(rrsets);
    },
    saveZone() {
      var that = this;
      this.saving = true;
      var zone = { ...this.zone };
      zone.rrsets = this.syncedRrsets;
      this.$http
        .put("services/dns/forward/" + this.zoneId, zone)
        .then((response) => {
          that.getZone();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.saving = false;
        });
    },
    deleteZone() {
      var that = this;
      this.deleting = true;
      this.$http
        .delete("services/dns/forward/" + this.zoneId)
        .then((response) => {
          if (that.domainId)
            that.$router.push({
              name: "domain-service-details",
              params: { domainId: that.domainId },
            });
          else that.$router.push({ name: "domain-service" });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.deleting = false;
        });
    },
    exportZone() {
      var that = this;
      this.exporting = true;
      this.downloadFile(
        "services/dns/forward/" + this.zoneId + "/export",
        "get",
        {},
        this.zone.name + ".txt",
        "text/plain",
        function () {
          that.exporting = false;
        },
        null,
        true
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "export zone": "export zone",
    "delete zone": "delete zone",
    "delete zone?": "Delete zone?",
    "refresh": "Refresh",
    "retry": "Retry",
    "expire": "Expire",
    "TTL": "TTL",
    "TTL negative caching": "TTL Negative Caching",
    "zone resource records": "Resource Records",
    "zone settings": "Settings",
    "zone meta data": "meta data",
    "kind": "Kind",
    "zoneKindNative": "Native",
    "zoneKindMaster": "Master",
    "zoneKindSlave": "Slave",
    "masters": "Masters",
    "SOA edit": "SOA Edit",
    "SOA edit API": "SOA Edit API",
    "DNSSEC": "DNSSEC",
    "NSEC3 param": "NSEC3 Parameter",
    "SOA record": "SOA Record",
    "default primary server": "Default Primary Server",
    "hostmaster email": "Hostmaster Email",
    "serial": "Serial",
    "hostname": "Hostname",
    "ttl": "TTL",
    "comments": "Comments",
    "records": "Records",
    "type": "Type",
    "Press": "Press",
    "to create a new entry": "to create a new entry",
    "save zone": "save zone",
    "reload zone": "reload zone",
    "add resource record": "add resource record",
    "deleteZoneMsg": "Are you sure you want to delete the zone? This means that all zone data is irrevocably lost!",
    "delete": "delete",
    "cancel": "cancel"
  },
  "de": {
    "export zone": "Zone exportieren",
    "delete zone": "Zone löschen",
    "delete zone?": "Zone löschen?",
    "refresh": "Refresh",
    "retry": "Retry",
    "expire": "Expire",
    "TTL": "TTL",
    "TTL negative caching": "TTL Negative Caching",
    "zone resource records": "Ressource Einträge",
    "zone settings": "Einstellungen",
    "zone meta data": "Meta Data",
    "kind": "Typ",
    "zoneKindNative": "Native",
    "zoneKindMaster": "Master",
    "zoneKindSlave": "Slave",
    "masters": "Masters",
    "SOA edit": "SOA Edit",
    "SOA edit API": "SOA Edit API",
    "DNSSEC": "DNSSEC",
    "NSEC3 param": "NSEC3 Parameter",
    "SOA record": "SOA Eintrag",
    "default primary server": "Default Primary Server",
    "hostmaster email": "Hostmaster Email",
    "serial": "Serial",
    "hostname": "Hostname",
    "ttl": "TTL",
    "comments": "Kommentare",
    "records": "Einträge",
    "type": "Typ",
    "Press": "Drücke",
    "to create a new entry": "um einen neuen Eintrag anzulegen",
    "save zone": "Zone speichern",
    "reload zone": "Zone neu laden",
    "add resource record": "Ressource Eintrag hinzufügen",
    "deleteZoneMsg": "Wollen Sie die Zone wirklich löschen? Damit gehen alle Zonen Daten unwiderruflich verloren!",
    "delete": "löschen",
    "cancel": "abbrechen"
  }
}
</i18n>
