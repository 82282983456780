<template>
  <div>
    <v-row align="end" dense>
      <v-col cols="1">
        <validation-provider
          :vid="name + 'Priority'"
          :name="$t('priority')"
          rules="required|integer|min:0|max:65535"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="priority"
            :name="name + 'Priority'"
            :label="$t('priority')"
            :error-messages="errors"
            :success="dirty && valid"
            :class="classes"
            single-line
            :clearable="!isMobile"
            :disabled="readOnly"
            dense
            v-disabled-icon-focus
            type="number"
            @input="update"
          />
        </validation-provider>
      </v-col>
      <v-col cols="3">
        <validation-provider
          :vid="name + 'Target'"
          :name="$t('target')"
          rules="dns"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="target"
            :name="name + 'Target'"
            :label="$t('target')"
            :error-messages="errors"
            :success="dirty && valid"
            :class="classes"
            single-line
            :clearable="!isMobile"
            :disabled="readOnly"
            dense
            v-disabled-icon-focus
            @input="update"
          />
        </validation-provider>
      </v-col>
      <v-col cols="8">
        <validation-provider
          :vid="name + 'Params'"
          :name="$t('parameters')"
          rules=""
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-combobox
            :name="name + 'Params'"
            v-model="params"
            :items="paramItems"
            :label="$t('parameters')"
            :error-messages="errors"
            :success="dirty && valid"
            :class="classes"
            :clearable="!isMobile"
            :disabled="readOnly"
            append-icon=""
            single-line
            hide-selected
            hide-no-data
            multiple
            small-chips
            flat
            dense
            v-disabled-icon-focus
            @input="update"
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-bind="attrs" :input-value="selected" label small>
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                  :disabled="readOnly"
                >
                  $delete
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-chip label small>
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordHttps",
  mixins: [formatDnsEntry, isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    hostname: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    priority: null,
    target: null,
    paramItems: [],
    disabled: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        this.priority = 1;
        this.target = this.toAscii(this.trimDotsRight(self.hostname || ""));
        this.paramItems = [];

        if (this.value != null) {
          var arr = (this.value.content || "").split(/\s+/);
          if (arr.length > 0) this.priority = Number(arr[0] || 0) || null;
          if (arr.length > 1)
            this.target = this.toAscii(this.trimDotsRight(arr[1] || ""));
          if (arr.length > 2) {
            for (let i = 2; i < arr.length; i++) {
              var param = this.trimParam(arr[i]);
              if (param != null) this.paramItems.push(param);
            }
          }
          this.disabled = this.value.disabled;
        }
      },
    },
  },
  computed: {
    params: {
      get() {
        return this.paramItems;
      },
      set(newValue) {
        this.paramItems = this.trimList(newValue);
      },
    },
  },
  methods: {
    trimParam(value) {
      var that = this;
      var key = null;
      var val = null;
      var params = value
        .split(/=/)
        .map((e) => e.trim())
        .map((e) => that.trimQuotationMarks(e))
        .map((e) => e.trim())
        .map((e) => e.replace(/[\s,;]$/, ""))
        .map((e) => e.trim())
        .filter((e) => e != null && e != "");
      if (params.length == 0) return null;
      if (params.length > 0) key = params[0].toLowerCase();
      if (params.length > 1) {
        key = params[0].toLowerCase();
        val = params[1];
      }
      if (
        [
          "mandatory",
          "alpn",
          "port",
          "echconfig",
          "ipv4hint",
          "ipv6hint",
        ].includes(key) ||
        (key.startsWith("key") && key != "key65535")
      )
        if (key != null && val != null) return key + "=" + val;
        else return key;
      else return null;
    },
    trimList(arr) {
      var that = this;
      return arr
        .map((e) => that.trimParam(e))
        .filter((e) => e != null && e != "");
    },
    update() {
      var content =
        (this.priority != null ? this.priority : 0) +
        " " +
        this.trimDotsRight(this.target || "") +
        ". " +
        this.trimList(this.paramItems).join(" ");
      this.$emit("input", {
        content: content,
        disabled: this.disabled,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "parameters": "Parameters",
    "priority": "Priority",
    "target": "Target"
  },
  "de": {
    "parameters": "Parameter",
    "protocol": "Protokoll",
    "priority": "Priorität",
    "target": "Ziel"
  }
}
</i18n>
