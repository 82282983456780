<template>
  <validation-provider
    :vid="name"
    :name="$t('text')"
    rules="required"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-textarea
      v-model="text"
      :name="name"
      :label="$t('text')"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      single-line
      :clearable="!isMobile"
      :disabled="readOnly"
      rows="1"
      auto-grow
      dense
      v-disabled-icon-focus
      @input="update"
    />
  </validation-provider>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordTxt",
  mixins: [formatDnsEntry, isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    text: null,
    disabled: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          this.text = this.value.content;
          this.disabled = this.value.disabled;
        }
      },
    },
  },
  methods: {
    update() {
      var rec = {
        content: this.text,
        disabled: this.disabled,
      };
      this.$emit("input", rec);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "text": "Text"
  },
  "de": {
    "text": "Text"
  }
}
</i18n>
