<template>
  <v-row align="end" dense>
    <v-col cols="2">
      <validation-provider
        :vid="name + 'Prio'"
        :name="$t('priority')"
        rules="required|integer"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="prio"
          :name="name + 'Prio'"
          :label="$t('priority')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          type="number"
          @input="update"
        />
      </validation-provider>
    </v-col>
    <v-col cols="10">
      <validation-provider
        :vid="name + 'Hostname'"
        :name="$t('hostname')"
        rules="required|dns"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-model="host"
          :name="name + 'Hostname'"
          :label="$t('hostname')"
          :error-messages="errors"
          :success="dirty && valid"
          :class="classes"
          single-line
          :clearable="!isMobile"
          :disabled="readOnly"
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordMx",
  mixins: [formatDnsEntry, isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    host: null,
    prio: null,
    disabled: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          var arr = (this.value.content || "").split(" ", 2);
          if (arr.length > 1) {
            this.prio = Number(arr[0] || 0) || null;
            this.host = arr[1];
          } else this.host = this.value.content;
          this.disabled = this.value.disabled;
        }
      },
    },
  },
  methods: {
    update() {
      this.$emit("input", {
        content:
          (this.prio != null ? this.prio : "") +
          " " +
          (this.host != null ? this.host : ""),
        disabled: this.disabled,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "priority": "Priority",
    "hostname": "Hostname"
  },
  "de": {
    "priority": "Priorität",
    "hostname": "Hostname"
  }
}
</i18n>
