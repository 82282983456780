<template>
  <v-container>
    <v-row dense>
      <v-col cols="4">{{ $t("primary master nameserver") }}</v-col>
      <v-col cols="8">
        <validation-provider
          vid="primaryNS"
          :name="$t('primary master nameserver')"
          rules="required"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="primaryNS"
            name="primaryNS"
            :label="$t('primary master nameserver')"
            :error-messages="errors"
            :success="dirty && valid"
            :class="classes"
            single-line
            dense
            disabled
            v-disabled-icon-focus
            @input="update"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("hostmaster email") }}</v-col>
      <v-col cols="8">
        <v-text-field
          v-model="hostmasterEmail"
          name="hostnameEmail"
          :label="$t('hostmaster email')"
          single-line
          disabled
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("serial") }}</v-col>
      <v-col cols="8">
        <v-text-field
          v-model="serial"
          name="serial"
          :label="$t('serial')"
          single-line
          disabled
          dense
          v-disabled-icon-focus
          @input="update"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("refresh") }}</v-col>
      <v-col cols="8">
        <ttl-combobox
          v-model="refresh"
          name="refresh"
          :label="$t('refresh')"
          :read-only="readOnly"
          @input="update"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("retry") }}</v-col>
      <v-col cols="8">
        <ttl-combobox
          v-model="retry"
          name="retry"
          :label="$t('retry')"
          :read-only="readOnly"
          @input="update"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("expire") }}</v-col>
      <v-col cols="8">
        <ttl-combobox
          v-model="expire"
          name="expire"
          :label="$t('expire')"
          :read-only="readOnly"
          @input="update"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ $t("TTL negative caching") }}</v-col>
      <v-col cols="8">
        <ttl-combobox
          v-model="ttlNegCaching"
          name="ttlNegCaching"
          :label="$t('TTL negative caching')"
          :read-only="readOnly"
          @input="update"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import isMobile from "@/utils/mixins/isMobile";
import TtlCombobox from "@/components/services/domains/TtlCombobox";

export default {
  name: "ZoneRecordSoa",
  mixins: [formatDnsEntry, isMobile],
  components: {
    TtlCombobox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    primaryNS: null,
    hostmasterEmail: null,
    serial: null,
    refresh: null,
    retry: null,
    expire: null,
    ttlNegCaching: null,
    disabled: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          var arr = (this.value.content || "").split(" ");
          if (arr.length > 0)
            this.primaryNS = this.formatDnsEntry(arr[0], "NS");
          if (arr.length > 1)
            this.hostmasterEmail = this.formatDnsEntry(arr[1], "NS");
          if (arr.length > 2) this.serial = Number(arr[2] || 0);
          if (arr.length > 3) this.refresh = Number(arr[3] || 0);
          if (arr.length > 4) this.retry = Number(arr[4] || 0);
          if (arr.length > 5) this.expire = Number(arr[5] || 0);
          if (arr.length > 6) this.ttlNegCaching = Number(arr[6] || 0);
          this.disabled = this.value.disabled;
        }
      },
    },
  },
  methods: {
    update() {
      this.$emit("input", {
        content:
          (this.primaryNS != null
            ? this.formatDnsEntryReverse(this.primaryNS, "NS")
            : "") +
          " " +
          (this.hostmasterEmail != null
            ? this.formatDnsEntryReverse(this.hostmasterEmail, "NS")
            : "") +
          " " +
          (this.serial != null ? this.serial : "") +
          " " +
          (this.refresh != null ? this.refresh : "") +
          " " +
          (this.retry != null ? this.retry : "") +
          " " +
          (this.expire != null ? this.expire : "") +
          " " +
          (this.ttlNegCaching != null ? this.ttlNegCaching : ""),
        disabled: this.disabled,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "primary master nameserver": "Primary Master Nameserver",
    "hostmaster email": "Hostmaster Email",
    "serial": "Serial",
    "refresh": "Refresh",
    "retry": "Retry",
    "expire": "Expire",
    "TTL negative caching": "TTL Negative Caching"
  },
  "de": {
    "primary master nameserver": "Primary Master Nameserver",
    "hostmaster email": "Hostmaster Email",
    "serial": "Serial",
    "refresh": "Refresh",
    "retry": "Retry",
    "expire": "Expire",
    "TTL negative caching": "TTL Negative Caching"
  }
}
</i18n>
