<template>
  <validation-provider
    :vid="name"
    :name="$t('IP')"
    :rules="'required|' + (ipv6 ? 'ipv6' : 'ipv4')"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-text-field
      v-model="record.content"
      :name="name"
      :label="$t('IP')"
      :error-messages="errors"
      :success="dirty && valid"
      :class="classes"
      single-line
      :clearable="!isMobile"
      :disabled="readOnly"
      dense
      v-disabled-icon-focus
      @input="update"
    />
  </validation-provider>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ZoneRecordA",
  mixins: [isMobile],
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    ipv6: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    record: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null) {
          this.record = { ...this.value };
        }
      },
    },
  },
  methods: {
    update() {
      var rec = { ...this.record };
      this.$emit("input", rec);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "IP": "IP"
  },
  "de": {
    "IP": "IP"
  }
}
</i18n>
